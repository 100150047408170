<template>
  <div id="offers">
    <!-- Filters -->
    <OffersFilters v-show="showFilters" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- TABLE TOP -->
        <app-data-table-top
          table-name="quotes"
          :show-per-page="$can('OPERATOR_SUBSCRIPTION_VIEW')"
          :show-search="$can('OPERATOR_SUBSCRIPTION_VIEW')"
          :show-filter="$can('OPERATOR_SUBSCRIPTION_VIEW')"
          :show-add="$can('OPERATOR_SUBSCRIPTION_ADD')"
          :disabled="offersLoading"
          :per-page="numberOfItemsPerPage"
          :per-page-options="dataTable.parPageOption"
          :search-filter-options="searchFilterOptions"
          search-default-filter="offerReference"
          :filters-count="filtersCount"
          condensed="lg"
          size="md"
          @table-select-per-page=";(numberOfItemsPerPage = $event), fetchOffers(true)"
          @table-search-text=";(searchText = $event), fetchOffers(true)"
          @table-search-filter=";(searchFilterSelection = $event), fetchOffers(true)"
          @table-filter="showFilters = $event"
          @table-add="addOffer()"
        />
      </div>
      <!-- TABLE -->
      <b-table
        ref="offers-table"
        class="position-relative table-dropdown-action"
        primary-key="id"
        :items="offers"
        :fields="offersFields"
        :empty-text="$t('common.no_record_found')"
        :busy="offersLoading"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        no-local-sorting
        no-sort-reset
        hover
        show-empty
        responsive
        @sort-changed="updateSort"
      >
        <!-- LOADING -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <!-- CELL -->
        <!-- id -->
        <template #cell(id)="data">
          <b-link
            :to="{
              name: 'offerInformationsShow',
              params: { id: data.item.id, editionMode: 'show' },
            }"
            class="font-weight-bold d-block text-nowrap"
          >
            #{{ data.item.id }}
          </b-link>
        </template>
        <!-- offerReference -->
        <template #cell(offerReference)="data">
          {{ data.item.reference | trans }}
        </template>
        <!-- mainTypology -->
        <template #cell(mainTypology)="data">
          {{ data.item.mainTypology.name | trans }}
        </template>
        <template #cell(offerType)="data">
          <span
            :class="{
              'custom-type': data.item.type === 'custom',
              'standard-type': data.item.type === 'standard',
            }"
          >
            {{ data.item.type|enumTranslate('offer_type') }}
          </span>
        </template>
        <!-- lastUpdate -->
        <template #cell(lastUpdate)="data">
          <span class="text-nowrap">
            {{ data.item.lastUpdate | dateFormatUtc }}
          </span>
        </template>
        <!-- validityDate -->
        <template #cell(validityDate)="data">
          <span class="text-nowrap">
            {{ data.item.validityDate | dateFormatUtc }}
          </span>
        </template>
        <!-- reconductible -->
        <template #cell(reconductible)="data">
          <b-badge v-if="data.item.reconductible" pill variant="light-success">
            {{ $t('common.yes') }}
          </b-badge>
          <b-badge v-else pill variant="light-danger"> {{ $t('common.no') }}</b-badge>
        </template>
        <!-- subscribersNumber -->
        <template #cell(subscribersNumber)="data">
          <b-badge pill variant="primary">
            {{ data.item.subscribersNumber }}
          </b-badge>
        </template>
        <!-- active -->
        <template #cell(active)="data">
          <b-badge v-if="data.item.active" pill variant="light-success">
            {{ $t('common.yes') }}
          </b-badge>
          <b-badge v-else pill variant="light-danger"> {{ $t('common.no') }}</b-badge>
        </template>
        <!-- action -->
        <template #cell(actions)="data">
          <app-data-table-actions
            :table-name="'offers' + data.item.id"
            :show-show="$can('OPERATOR_SUBSCRIPTION_VIEW') && data.item.subscribersNumber > 0"
            :show-duplicate="$can('OPERATOR_SUBSCRIPTION_ADD')"
            :show-edit="$can('OPERATOR_SUBSCRIPTION_EDIT') && data.item.subscribersNumber === 0"
            :show-delete="$can('OPERATOR_SUBSCRIPTION_DELETE')"
            @show="
              $router.push({
                name: 'offerInformationsShow',
                params: {
                  id: data.item.id,
                  editionMode: 'show',
                  subscribersNumber: data.item.subscribersNumber,
                },
              })
            "
            @duplicate="duplicateOffer(data.item)"
            @edit="
              $router.push({
                name: 'offerInformationsEdit',
                params: {
                  id: data.item.id,
                  editionMode: 'edit',
                  subscribersNumber: data.item.subscribersNumber,
                },
              })
            "
            @delete="data.item.subscribersNumber === 0 ? softDeleteOffer(data.item) : toggleSoftDelete(data.item)"
          />
        </template>
      </b-table>
      <!--TABLE BOTTOM-->
      <div class="mx-2 mb-2">
        <app-data-table-bottom
          table-name="offers"
          :page="page"
          :per-page="numberOfItemsPerPage"
          :total-count="pagination.totalItems"
          @pagination=";(page = $event), fetchOffers(true)"
        />
      </div>
    </b-card>
    <OfferInformationsLight
      :offer-edition="offerEdition"
      :offer-edition-mode="offerEditionMode"
      :offer="selectedOffer"
      :offer-loading="offersLoading"
      @offerUpdated="postOffer($event)"
      @editionUpdated="offerEdition = $event"
    />
    <OfferDeletionSoft
      :offer-deletion="offerDeletion"
      :offer-deletion-mode="offerDeletionMode"
      :offer="reaffectedOffer"
      :offer-loading="offersLoading"
      @offerUpdated="softDeleteOffer($event)"
      @editionUpdated="offerDeletion = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import UIHelper from '@/helpers/ui'

import { deleteOfferDeleteRequest, postOfferRequest } from '@/request/globalApi/requests/offerRequests'

import AppDataTableBottom from '@/components/AppDataTableBottom.vue'
import AppDataTableTop from '@/components/AppDataTableTop.vue'
import AppDataTableActions from '@/components/AppDataTableActions.vue'

import OffersFilters from './OffersFilters.vue'
import OfferInformationsLight from './OfferInformationsLight.vue'
import OfferDeletionSoft from './OfferDeletionSoft.vue'

export default {
  name: 'OffersList',

  components: {
    AppDataTableBottom,
    OffersFilters,
    OfferInformationsLight,
    AppDataTableTop,
    AppDataTableActions,
    OfferDeletionSoft,
  },

  mixins: [UIHelper],

  data() {
    return {
      showFilters: false,
      offerEdition: false,
      offerEditionMode: '',
      offerDeletion: false,
      offerDeletionMode: '',
      reaffectedOffer: {},
      selectedOffer: { mainTypology: { id: null } },
    }
  },

  computed: {
    ...mapState('offer', ['offersLoading', 'offers', 'pagination', 'searchFilterOptions']),
    ...mapState('appConfig', ['dataTable', 'lang']),
    ...mapGetters('offer', ['filtersCount']),

    ...mapFields('offer', [
      'paginate.page',
      'paginate.numberOfItemsPerPage',
      'dataTableSort.sortBy',
      'dataTableSort.sortDesc',
      'searchFilterSelection',
      'searchText',
    ]),

    offersFields() {
      return [
        { key: 'id', label: this.$t('common.id'), sortable: true },
        {
          key: 'offerReference',
          label: this.$t('common.reference'),
          sortable: true,
        },
        {
          key: 'offerType',
          label: this.$t('common.type'),
          sortable: true,
        },
        {
          key: 'mainTypology',
          label: this.$t('offer.main_typology'),
          sortable: true,
        },
        {
          key: 'lastUpdate',
          label: this.$t('common.last_update'),
          sortable: true,
        },
        {
          key: 'validityDate',
          label: this.$t('common.validity_date'),
          sortable: true,
        },
        {
          key: 'reconductible',
          label: this.$t('offer.reconductible'),
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center text-nowrap',
        },
        {
          key: 'subscribersNumber',
          label: this.$t('offer.subscribers_number'),
          sortable: true,
          tdClass: 'text-center',
          thClass: 'text-center text-nowrap',
        },
        {
          key: 'active',
          label: this.$t('common.active'),
          sortable: true,
          tdClass: 'text-center',
        },
        {
          key: 'actions',
          label: this.$tc('action.title', 2),
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ]
    },
  },

  mounted() {
    this.fetchOffers(true)
  },

  methods: {
    ...mapActions('offer', ['fetchOffers', 'resetState', 'setSort']),

    addOffer() {
      this.selectedOffer = {
        id: null,
        reference: {},
        type: '',
        mainTypology: {
          id: null,
        },
        internalDescription: '',
        iconFileId: null,
        deleteOldIconFile: false,
        active: false,
      }
      this.offerEditionMode = 'add'
      this.offerEdition = true
    },

    duplicateOffer(offer) {
      this.selectedOffer = this._cloneDeep(offer)
      this.selectedOffer.id = null
      Object.keys(this.selectedOffer.reference).forEach(lang => {
        this.selectedOffer.reference[lang] += '_copy'
      })
      this.offerEditionMode = 'duplicate'
      this.offerEdition = true
    },

    toggleSoftDelete(offer) {
      this.reaffectedOffer = {
        ...offer,
        suggestedOffersIds: [],
        defaultOfferId: null,
      }
      this.offerDeletionMode = 'delete'
      this.offerDeletion = true
    },

    softDeleteOffer(offer) {
      deleteOfferDeleteRequest(
        offer.id,
        {
          suggestedOffersIds: offer.suggestedOffersIds,
          defaultOfferId: offer.defaultOfferId,
          redirectUrl: `${window.location.origin}/registration/offers`,
        },
        offer.reference[this.lang],
      ).then(response => {
        response && this.fetchOffers(true)
      })
    },

    postOffer(offer) {
      postOfferRequest(offer, offer.reference[this.lang]).then(() => {
        this.fetchOffers(true)
      })
    },

    updateSort(e) {
      this.sortBy = e.sortBy
      this.sortDesc = e.sortDesc
      this.setSort({ sortBy: e.sortBy, sortDesc: e.sortDesc })
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched[0].name !== 'offer') {
      this.resetState()
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 65px;
}
</style>
