var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"offer-informations-light"}},[_c('app-sidebar-form',{attrs:{"disabled":_vm.offerLoading,"edition":_vm.offerEdition,"edition-mode-title":_vm.offerEditionMode,"entity":_vm.offer,"title":_vm.$tc('offer.title', 1),"form-name":"offer-informations-light","no-pristine":""},on:{"update:edition":function($event){return _vm.$emit('editionUpdated', $event)},"update:entity":function($event){return _vm.$emit('offerUpdated', $event)}},scopedSlots:_vm._u([{key:"fields",fn:function(data){return [_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('app-form-text-input-itn',{attrs:{"default-lang":_vm.$store.state.auth.defaultLang,"label":_vm.$t('offer.reference'),"languages":_vm.$store.state.auth.activelang,"loading":_vm.offerLoading,"form-name":"offer-informations-light"},model:{value:(data.item.reference),callback:function ($$v) {_vm.$set(data.item, "reference", $$v)},expression:"data.item.reference"}})],1),_c('b-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('offer.main_typology'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"validation-required",attrs:{"label":_vm.$t('offer.main_typology'),"label-for":"offer-informations-light-main-typology"}},[_c('b-overlay',{attrs:{"show":_vm.offerLoading || _vm.organizationTypologiesLoading}},[_c('v-select',{staticClass:"select-size-md",attrs:{"clearable":false,"get-option-label":function (opt) { return _vm.$options.filters.trans(opt.name); },"options":_vm.organizationTypologies,"placeholder":_vm.$t('offer.main_typology'),"reduce":function (name) { return name.id; },"input-id":"offer-informations-light-main-typology"},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.no_option_found'))+" ")]},proxy:true}],null,true),model:{value:(data.item.mainTypology.id),callback:function ($$v) {_vm.$set(data.item.mainTypology, "id", $$v)},expression:"data.item.mainTypology.id"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('enum-select',{attrs:{"id":"offer-informations-light-type","label":_vm.$t('common.type'),"enum-class":"OfferType","overlay":_vm.offerLoading,"clearable":false,"required":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var text = ref.text;
var value = ref.value;
return [(value === 'standard')?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(text)+" ")]):(value === 'custom')?_c('b-badge',{attrs:{"pill":"","variant":"light-info"}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}},{key:"selected-option",fn:function(ref){
var text = ref.text;
var value = ref.value;
return [(value === 'standard')?_c('b-badge',{attrs:{"pill":"","variant":"light-success"}},[_vm._v(" "+_vm._s(text)+" ")]):(value === 'custom')?_c('b-badge',{attrs:{"pill":"","variant":"light-info"}},[_vm._v(" "+_vm._s(text)+" ")]):_vm._e()]}}],null,true),model:{value:(data.item.type),callback:function ($$v) {_vm.$set(data.item, "type", $$v)},expression:"data.item.type"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('offer.internal_description'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('offer.internal_description'),"label-for":"offer-internal-description"}},[_c('b-overlay',{attrs:{"show":_vm.offerLoading}},[_c('b-form-textarea',{attrs:{"id":"offer-informations-light-internal-description","placeholder":_vm.$t('offer.internal_description')},model:{value:(data.item.internalDescription),callback:function ($$v) {_vm.$set(data.item, "internalDescription", $$v)},expression:"data.item.internalDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('offer.icon'),"label-for":"offer-icon"}},[_c('b-overlay',{attrs:{"show":_vm.offerLoading || _vm.dropZoneLoading,"spinner-medium":""}},[_c('app-drop-zone',{ref:"offer-informations-light-icon",staticClass:"dropzone",attrs:{"id":"offer-informations-light-icon","options":_vm.dropzoneOptions},on:{"vdropzone-queue-complete":function($event){_vm.iconUploaded = true},"vdropzone-removed-file":function($event){return _vm.removeOfferIcon()},"vdropzone-file-added":function($event){return _vm.sendingFile($event, data.item, 'iconFileId', 'iconUploading', 'deleteOldIconFile', 'iconFileUrl', 'offer-informations-light-icon')}}})],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }